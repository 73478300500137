/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;600;700;900&display=swap");

* {
  font-family: "Kanit";
}

.app-input {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 64px;
  padding: 19px 16px;
  width: 100%;
}

.app-input:focus {
  border: 1px solid #2b77ff;
  outline: none !important;
}

.app-label {
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
}

.input-help {
  color: #838383;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  text-align: right;
  width: 100%;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}
html {
  overflow: hidden !important;
  width: 100% !important;
}

body {
  font-family: "Kanit", Roboto, "Helvetica Neue";
  height: 100% !important;
  margin: 0;
  overflow-y: auto !important;
  position: fixed !important;
  width: 100% !important;
}

body:has( .action-sheet-overlay) {
  overflow-y: hidden !important;
}

.mat-dialog-container {
  border-radius: 16px 16px 0px 0px !important;
  padding: 2px 0px 0px 2px !important;
  overflow: hidden !important;
}
.mat-dialog-content {
  overflow: unset !important;
}
img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.modal-content {
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
}
/* kanit-regular - latin */
@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-weight: 400;
  src: url("assets/kanitFont/Kanit-v12-thai-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/kanitFont/Kanit-v12-thai-regular.eot?#iefix")
      format("embedded-opentype"),
    url("assets/kanitFont/Kanit-v12-thai-regular.woff2") format("woff2"),
    url("assets/kanitFont/Kanit-v12-thai-regular.woff") format("woff"),
    url("assets/kanitFont/Kanit-v12-thai-regular.ttf") format("truetype"),
    url("assets/kanitFont/Kanit-v12-thai-regular.svg#Kanit") format("svg");
}
.tooltip-arrow {
  left: 39px !important;
}
.tooltip {
  left: 70px !important;
  pointer-events: all !important;
}
.tooltip-inner {
  max-width: 230px !important;
  background-color: #172735;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0582933);
  border-radius: 8px !important;
}
.tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #172735 !important;
}
.tooltip.show {
  opacity: 1 !important;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.tncContentHeader {
  font-size: 18px;
  font-family: "Kanit";
  font-weight: 600;
  line-height: 24px;
}
strong {
  font-weight: 600 !important;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.8);
}
